import React,{useState, useEffect} from "react";
import * as Yup from "yup";
import { useFormik, Form, FormikProvider , ErrorMessage} from 'formik';
import nicLogo from "../assets/nic.png";
import mahaForestLogo from "../assets/mahaforestdept-logo.png";
import ShowMessage from '../components/ShowMessage/ShowMessage';
import { RootState, AppDispatch } from '../Redux-Store/store';
import { useSelector, useDispatch } from 'react-redux';
import {LoginAction} from '../Redux-Store/actions/LoginAction'
import { useNavigate } from "react-router";
import { sha512 } from 'js-sha512'; // Import sha512
import { Eye } from 'react-bootstrap-icons';
import { EyeSlash } from 'react-bootstrap-icons';


type Props = {};

const AdminLogin = (props: Props) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [messageType, setMessageType] = useState<'success' | 'error'>('success')
  const [isShow, setIsShow] = useState(false)
  const [navigateAfterMessage, setNavigateAfterMessage] = useState(false)
  const [kioskId,setKioskId] = useState()
  const [message, setMessage] = useState<{ text: string; type: 'success' | 'error' | null }>({ text: '', type: null });
  const [showPassword, setShowPassword] = useState(false)

  const navigate= useNavigate()




  const dispatch: AppDispatch = useDispatch();
  const {login,is_error,isLoading } = useSelector((state: RootState) => state.login);
  console.log("users", login)
  // console.log("message",message)
  // localStorage.setItem('kioskId',"1")



  const handleToggleButton = () =>{
    setShowPassword((prev) => !prev )
  }

  interface loginData {
    userField: string,
    password: string
  }
  
  
  const loginSchema= Yup.object({
    userField: Yup.string()
    .max(30, 'Username must be at most 30 characters long')
    .required('Username is Required'),
    password: Yup.string()
    .min(8, 'Password must be at least 8 characters long')
    .max(15, 'Password must be at most 15 characters long')
    .matches(/[0-9]/, 'Password must contain at least one number')
    .required('Password is Required'),
  });
  


  const formik = useFormik<loginData>({
    initialValues:{
      userField: '',
      password:""
    },
    validationSchema:loginSchema,
    onSubmit:(value, { resetForm })=>{
  const hashedPassword = sha512(value.password);
  const hashUpdated = hashedPassword.toString().toUpperCase();

  console.log("Hashed Password:", hashUpdated);
  // navigate('/otpVerification')
    
 
  let obj = {
    username : value.userField,
    password : hashUpdated
  }
  localStorage.setItem('username',value.userField)
  localStorage.setItem('password',hashUpdated)
  dispatch(LoginAction(obj)).then((response:any)=>{
   console.log('rrrrrr', response);
    
   if (response.payload.meta.is_error===false) {
//       setKioskId(response.payload.data.kiosks[0].kiosk_id)
//localStorage.setItem('kiosk_id',response.payload.data.kiosks[0].kiosk_id)
   setMessageType("success")
 setErrorMessage(response?.payload?.meta?.message);
setMessage({ text: response?.payload?.meta?.message, type: 'success' });
setIsShow(true);
setNavigateAfterMessage(true);
console.log('response', response?.data?.meta?.message);
//       // navigate('/otpVerification') 
//       // setTimeout(() => setIsShow(false), 1500);
   }else if ( response?.payload.meta.status === 401 || response.payload.meta.is_error === true) {
 setMessageType('error')
      setErrorMessage(response?.payload?.meta?.message);
      setIsShow(true);
      setNavigateAfterMessage(false);
       resetForm();
     } else {
       console.log('else');
     }
   })
   .catch((error) => {
    console.error('Error:', error);
  });
setTimeout(() => {setIsShow(false)}, 3000);
}
  })


  useEffect(() => {
    if (navigateAfterMessage) {
      const timer = setTimeout(() => {
        navigate('/otpVerification', {state:kioskId});
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [navigateAfterMessage, navigate]);


  const {handleSubmit,touched,errors,getFieldProps} = formik

  return(

    <main className="bgMain">
    <div className="container">
      <div className="row justify-content-center align-items-center height100">
        <div className="col-md-9 align-self-center">
          <div className="card card-transparancy">
            <div className="card-body">
              <div className="text-center">
                <img
                  src={mahaForestLogo}
                  alt="Maharashtra Forest Logo"
                  width={100}
                />
                     </div>
    <div className="d-flex justify-content-center align-items-center">
           <form action="" onSubmit={handleSubmit} >
              <div className="input-group input-group-lg flex-nowrap mt-3">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Enter Username"
                  aria-label="Name"
                  aria-describedby="addon-wrapping"
                  {...getFieldProps('userField')}
                />
              </div>
              {touched.userField && errors.userField ? (
                        <div style={{ color: "red", textAlign: "left" , marginLeft: 18}}>
                          {errors.userField}
                        </div>
                      ) : null}

                <div className="input-group input-group-lg flex-nowrap mt-3">
                <input
                  type={showPassword? "text": "password"}
                  className="form-control"
                  placeholder="Enter Password"
                  aria-label="Password"
                  aria-describedby="addon-wrapping"
                  {...getFieldProps('password')}
                />
                  <span
        style={{
          position: 'absolute',
          right: '10px',
          top: '50%',
          transform: 'translateY(-50%)',
          cursor: 'pointer',
          zIndex: 10, 
          background:"white"
        }}
        onClick={handleToggleButton}
      >
        {!showPassword ? <EyeSlash size={30}/> :  <Eye size={30}/> } 
        {/* Show eye icon based on visibility */}
      </span>

              </div>
              {touched.password && errors.password ? (
                        <div style={{ color: "red", textAlign: "left" , marginLeft:18}}>
                          {errors.password}
                        </div>
                      ) : null}

              <div className="mt-4 mb-3">
                <button
                  className="btnPrimary btn-lg w-100"
                  type="submit"
                >
            Login
                </button>
  </div>
  </form>

  <ShowMessage
          onClose={() => setIsShow(false)}
          title={errorMessage}
          type={messageType}
          open={isShow}
        />
  </div>
  </div>
  <div className="card-footer bg-white text-center">
                  <p className="mb-1 textLight">Powered by</p>
                  <img
                    src={nicLogo}
                    alt="National Informatics Center (NIC)"
                    width={100}
                    // onDoubleClick={handleAdminLogin}
                  />
                </div>
            
            </div>
          </div>
        </div>
        </div>
      </main>
 



  )

};

export default AdminLogin;
