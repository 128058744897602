import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { MdOutlineArrowBack } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

import BookingDetails from "../components/ConfirmTickets/BookingDetails";
import TicketCharges from "../components/ConfirmTickets/TicketCharges";
import { useDispatch } from "react-redux";
import { ClearBookingSeats } from "../Redux-Store/actions/ConfirmBookingdetails";
import { AppDispatch } from "../Redux-Store/store";

type Props = {};



const ConfirmTickts = (props: Props) => {
  const navigate= useNavigate()
  const dispatch: AppDispatch = useDispatch();
  const location = useLocation()
  const [updatedData,setUpdatedData] = useState();
console.log("location", location)
const bookingId = location.state.confirmTicketData.booking_id
console.log("bookingId",bookingId)


const visitors = location.state || [];
console.log("visitors",visitors.state)

const {state} = useLocation();

useEffect(()=>{
  setUpdatedData(location.state.visitors);
},[location.state])

const handleMorePassenger = () => {

  dispatch(ClearBookingSeats({
    booking_id : location.state.confirmTicketData.booking_id,
  })).then((response:any)=>{
    console.log("response123",response )
    if (response.payload.meta.status=== 200) {
      navigate('/vehicleSelection',{
        state: {
          visitors:visitors,
          bookedSeats:location.state.bookedSeats,
          bookingDetails: location.state.confirmTicketData,
          reg_no: location.state.reg_no,
          ...state
        },
      })
    } else {
      console.error("Error in booking:", response.meta);
    }
  });

  // dispatch(ClearBookingSeats({booking_id : location.state.bookingDetails.booking_id}))

}
  
  return (
    <section className="container">
      <div className="row">
        <div className="col-md-12 border-bottom py-2">
          <header>
            <h3 className="heading">Confirm Tickets</h3>
            <Link to={"/date-selection"} className="textSecondary">
              <MdOutlineArrowBack /> BACK TO DATE & GATE SELECTION
            </Link>
          </header>
        </div>
        <div className="col-md-6 pt-2 mt-1">
          <BookingDetails  visitorsName= {location.state.visitors}
          bookedSeats={location.state.bookedSeats}
          reg_no ={location.state.reg_no}
          handleMorePassenger = {()=>handleMorePassenger()}
          bookingId={bookingId}
          />
        </div>
        <div className="col-md-6 pt-2 mt-1 border-start">
          <TicketCharges confirmTicketDataDetails={location.state.confirmTicketData} 
          bookedSeats={location.state.bookedSeats} 
          gateId={location.state.gateId}
          planId={location.state.planId}
          />
        </div>
      </div>
    </section>
  );
};

export default ConfirmTickts;
